header {
  width: 100%;
  height: 550px;
  background-size: cover;
  background-position: center;
  /*background-image: url(/src/img/photos/broncos6.jpg);*/
  background-position-y: top;
  text-align: center;
}


body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', 'PT Sans',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-text-stroke: 0.2px black;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/*******************************************Navbar****************************************************/
.logoPrincipal {
  height: 100px;
  width: 100px;
}

.navbarBroncos.active {
  background: linear-gradient(90deg, rgba(38, 71, 152, 0.665893271461717) 31%, rgba(224, 5, 17, 1) 150%);
  -webkit-text-stroke: 0.5px rgb(224, 5, 17);
  position: sticky;

}

.navbarTitle {
  font-weight: bold;
  /*margin: auto !important;*/
  font-size: 50px !important;
  color: rgb(38, 71, 152) !important;
  -webkit-text-stroke: 1.8px rgb(224, 5, 17) !important;
  margin-right: 3% !important;
  margin-left: 2% !important;
}

.navbarTitle:hover {
  color: white !important;
  -webkit-text-stroke: 1.8px rgb(38, 71, 152) !important;
}

.navbarSubtitles {
  font-size: 30px;
  color: rgb(38, 71, 152) !important;
  -webkit-text-stroke: 0.5px rgb(224, 5, 17) !important;
  margin: 15px;
  font-weight: bold;
}

.navbarSubtitles:hover {
  transform: scale(1.3);
}

.navbarBroncos.active .navbarSubtitles {
  color: white !important;
}

@media(max-width: 1320px) {
  .navbarSubtitles {
    margin: 5px;
    font-size: 20px;
  }

}

@media(max-width: 1040px) {
  .navbarSubtitles {
    margin: 2px;
    font-size: 18px;
  }

}

@media(max-width:991px){
  .navbarSubtitles{
    color: white !important;
    font-size: 25px;
  }
}


@media(max-width: 636px) {
  .navbarTitle {
    font-size: 35px !important;
    -webkit-text-stroke: 1.3px rgb(224, 5, 17) !important;
  }

  .logoPrincipal {
    height: 50px;
    width: 50px;
  }

}

@media(max-width: 500px) {
  .navbarTitle {
    font-size: 27px !important;
    -webkit-text-stroke: 1.0px rgb(224, 5, 17) !important;
  }
}



/****************************************End*Navbar***************************************************/
/***************************************Social*Network************************************************/
.fondoSocial {
  background: rgb(38, 71, 152);
  background: linear-gradient(90deg, rgba(38, 71, 152) 0%, rgba(224, 5, 17, 1) 98%);

}

.iconos {
  width: 2rem;
  height: 2rem;
  fill: hsla(0, 0%, 100%, .6);
  margin-left: 1.5%;
}

.whatsapp {
  position: fixed;
  width: 60px;
  height: 60px;
  bottom: 40px;
  right: 40px;
  background-color: #25d366;
  color: #FFF;
  border-radius: 50px;
  text-align: center;
  font-size: 30px;
  z-index: 100;
}

.whatsapp-icon {
  margin-top: 13px;
}


/*
@media(max-width: 850px){
  .logoFecofa{
    height: 100px;
    width: 100px;
 }
}

@media(max-width: 636px){
  .logoFecofa{
    height: 80px;
    width: 80px;
 }
}*/

/**************************************End*Social*Network*********************************************/
/********************************************Footer***************************************************/
footer {
  background: rgb(38, 71, 152);
  background: linear-gradient(90deg, rgba(38, 71, 152) 0%, rgba(224, 5, 17, 1) 98%);
  color: black;
  font-size: large;
  
}

.iconsFooter {
  height: 25px;
  width: 25px;
  float: left;
  margin-left: 1rem;
}

.logoText {
  margin-left: 10%;
  hyphens: auto;
  word-wrap: break-word;
  word-break: break-word;
}

.aText {
  margin-left: 5%;
  color: white;
}

.aText:hover {
  color: rgb(55, 51, 51);
}

.logoFecofa {
  height: 150px;
  width: 150px;
  display:block;
  margin:auto;
}

.TarjetasFooter {
  margin-top: 15%;
  margin-bottom: 15%;
  height: 80%;
  background-color: rgba(255, 0, 0, 0.07) !important;
  color: white !important;
  font-weight: 400;
}

.TarjetasFooter h2{
  margin-top: 1rem;
  text-align: center;
  margin-bottom: 2rem;
  color:white;
  -webkit-text-stroke: 0.7px rgb(224, 5, 17) !important;
}

.TarjetasFooter p{
  margin-left: 1rem;
}

@media(max-width: 950px) {}

/*******************************************End*Footer************************************************/
/*******************************************Contenido*************************************************/
.Contenedor {
  background-color: black;
  color: whitesmoke;
}

/******************************************End*Contenido**********************************************/
