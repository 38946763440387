.Contenedor{
    color: aliceblue;
    background-color: black;
    text-align: center;
}

.Contenedor h1{
    font-size: 50px;
}

