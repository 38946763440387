.Canchas {
    height: 500px;
    width: 500px;
    display: block;
    margin: auto;
}
.tituloCancha{
    text-align: center;
}

.titulo{
    text-align: center;
    font-weight: bold;
}

@media(max-width: 1200px) {
    .Canchas {
        height: 450px;
        width: 450px;
    }

    .tituloCancha {
        font-size: large;
    }
}

@media(max-width: 992px) {
    .tituloCancha {
        font-size: x-large;
    }
}

@media(max-width: 630px) {
    .Canchas {
        height: 380px;
        width: 380px;
    }



}

@media(max-width: 410px) {
    .Canchas {
        height: 300px;
        width: 300px;
    }
}